import HttpService from "../HttpService";
import Jury from "@/models/sportevent/Jury";
import BaseService from "../BaseService";

class JuryService extends BaseService<Jury> {
  public url: string;
  constructor(url = "sportevent/jury/") {
    super(url, "");
    this.url = url;
  }

  async getByStartBlockAndDisciplineCategory(
    startblockId: string,
    disciplineCategoryId: string
  ) {
    const response = await HttpService.get<Jury>(
      `${this.url}bystartblockanddisciplinecategory/${startblockId}/${disciplineCategoryId}/`
    );
    return response;
  }

  async getAllByStartBlock(startblockId: string) {
    const response = await HttpService.get<Jury[]>(
      `${this.url}bystartblock/${startblockId}/`
    );
    return response;
  }

  async getAllBySportevent(sporteventId: string) {
    const response = await HttpService.get<Jury[]>(
      `${this.url}bysportevent/${sporteventId}/`
    );
    return response;
  }
}
export default new JuryService();
