
import { Component, Vue, Watch } from "vue-property-decorator";

import Multiselect from "vue-multiselect";

import StartGroup from "../../models/sportevent/StartGroup";
import Discipline from "../../models/sporteventCalculation/Discipline";
import Jury from "../../models/sportevent/Jury";
import JuryService from "../../services/sportevent/JuryService";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import DisciplineCategory from "../../models/sporteventCalculation/DisciplineCategory";
import StartGroupService from "../../services/sportevent/StartGroupService";
import SporteventService from "../../services/sportevent/SporteventService";
import StarterService from "../../services/sportevent/StarterService";
import StartBlock from "../../models/sportevent/StartBlock";
import SporteventCompetitionService from "../../services/sportevent/SporteventCompetitionService";
import DisciplineService from "@/services/sporteventCalculation/DisciplineService";
import Sportevent from "@/models/sportevent/Sportevent";

@Component({
  components: {
    Multiselect,
  },
})
export default class ListScoringSheetsByDisciplineCategoryComponent extends Vue {
  // Loading
  public loadingDisciplineCategories = true;
  public loading = false;

  public startGroups: StartGroup[] = [];

  public currentDisciplineCategory: DisciplineCategory =
    new DisciplineCategory();
  public allDisciplineCategories: DisciplineCategory[] = [];
  public isSwimDiscipline = false;

  public firstDiscipline: Discipline = new Discipline();

  public allJuries: Jury[] = [];

  public allSporteventCompetitions: SporteventCompetition[] = [];

  public sportevent?: Sportevent = undefined;
  async mounted(): Promise<void> {
    this.sportevent = SporteventService.get();
    this.getJuries();
    this.getAllDisciplineCategories();
  }

  async disciplineCategoriesChanges(): Promise<void> {
    this.loading = true;
    this.startGroups = [];
    this.firstDiscipline = new Discipline();
    this.getAllScorringTables();
  }

  async getAllScorringTables(): Promise<void> {
    let tmpStartGroups = await StartGroupService.getAllBySportevent(true);
    this.allSporteventCompetitions =
      await StarterService.getAllSporteventCompetitions();
    if (this.currentDisciplineCategory.id !== undefined) {
      tmpStartGroups.forEach((startgroup: StartGroup) => {
        // Finden der Startgruppen mit DisciplinecategoryId
        startgroup.sporteventCompetitions.forEach(async (ec) => {
          const disciplinecategoryIndex = ec.disciplines.findIndex(
            (d) => d.disciplineCategory.id === this.currentDisciplineCategory.id
          );

          if (-1 !== disciplinecategoryIndex) {
            startgroup.starters.sort((a, b) =>
              a.positionInStartgroup > b.positionInStartgroup ? 1 : -1
            );
            this.startGroups.push(startgroup);

            if (this.firstDiscipline.id === undefined && ec.id !== undefined) {
              this.firstDiscipline = this.getCurrentDisciplines(ec.id)[0];
              if (
                this.firstDiscipline.judgeType === undefined &&
                this.firstDiscipline.id !== undefined
              ) {
                this.firstDiscipline = await DisciplineService.get(
                  this.firstDiscipline.id
                );
              }

              if (this.firstDiscipline.judgeType.shortname === "SW")
                this.isSwimDiscipline = true;
              else this.isSwimDiscipline = false;
            }
          }
        });
      });
    }
    this.startGroups = this.startGroups.filter(
      (startGroup, i, arr) => arr.findIndex((s) => s.id === startGroup.id) === i
    );

    //console.log(this.startGroups);
    this.loading = false;
  }

  getDisciplineType(currentDisciplineName: string): string {
    return currentDisciplineName.replace(
      this.currentDisciplineCategory.name,
      ""
    );
  }

  getCurrentDiscipline(sporteventCompetitionId: string): Discipline {
    const discipline = this.getCurrentDisciplines(sporteventCompetitionId);
    if (discipline !== undefined) {
      return discipline[0];
    }
    return this.firstDiscipline;
  }

  getCurrentDisciplines(sporteventCompetitionId: string): Discipline[] {
    let sc = this.allSporteventCompetitions.find(
      (sc) => sc.id === sporteventCompetitionId
    );

    if (sc !== undefined)
      return sc.disciplines
        .filter(
          (d) => d.disciplineCategory.id === this.currentDisciplineCategory.id
        )
        .map((d) => new Discipline(d));
    return [];
  }

  getCurrentJuryCount(
    startblock: StartBlock,
    disciplineCategory: DisciplineCategory
  ): number {
    console.log("allJuries:", this.allJuries);
    const jury = this.allJuries.filter(
      (j) =>
        j.startBlock.id === startblock.id &&
        j.disciplineCategory.id === disciplineCategory.id
    )[0];
    console.log("jury:", jury);
    if (jury !== undefined && jury.judges.length >= 2) {
      return jury.judges.length;
    }
    return 2;
  }

  async getJuries(): Promise<void> {
    if (this.sportevent != undefined && this.sportevent.id !== undefined) {
      const juries = await JuryService.getAllBySportevent(this.sportevent.id);
      console.log("juries", juries);
      if (juries !== undefined) {
        this.allJuries = juries;
      }
    }
  }

  async getAllDisciplineCategories(): Promise<void> {
    let tmpDisciplines: Discipline[] = [];

    const sporteventCompetitions =
      await SporteventCompetitionService.getBySportevent(
        this.$store.state.localstore_sportevent.sportevent.id
      );
    if (sporteventCompetitions !== undefined) {
      sporteventCompetitions.forEach(
        (sporteventCalculation: SporteventCompetition) => {
          sporteventCalculation.disciplines.forEach((discipline) => {
            if (
              tmpDisciplines.findIndex((d) => d.id === discipline.id) === -1
            ) {
              tmpDisciplines.push(discipline);
            }
          });
        }
      );

      let tmpDisciplineCategories: DisciplineCategory[] = [];
      tmpDisciplines.forEach((discipline: Discipline) => {
        if (
          tmpDisciplineCategories.findIndex(
            (dc) => dc.id === discipline.disciplineCategory.id
          ) === -1
        ) {
          tmpDisciplineCategories.push(discipline.disciplineCategory);
        }
      });
      this.allDisciplineCategories = tmpDisciplineCategories;
    }

    this.loadingDisciplineCategories = false;
  }

  getDisciplineCategoryName(disciplineCategory: DisciplineCategory): string {
    return disciplineCategory.name;
  }
}
